/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {css} from '@gsk-platforms/mwc-base/base-element';

export const style = css`/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
@keyframes mwc-circular-progress-rotate {
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}
@keyframes mwc-circular-progress-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
:host {
  display: block;
}

.mwc-circular-progress {
  line-height: 1;
}
.mwc-circular-progress__bg {
  stroke-dasharray: 0 !important;
  stroke-dashoffset: 0 !important;
  animation: none !important;
}
.mwc-circular-progress--closed {
  display: none;
}
.mwc-circular-progress--primary {
  color: #6200ee;
}
.mwc-circular-progress--secondary {
  color: #018786;
}
.mwc-circular-progress circle {
  stroke: currentColor;
}

.mwc-circular-progress.mwc-circular-progress--fixed {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mwc-circular-progress.mwc-circular-progress--fixed circle {
  transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mwc-circular-progress.mwc-circular-progress--indeterminate {
  animation: 1.4s linear 0s infinite normal none mwc-circular-progress-rotate;
  animation-play-state: unset;
}
.mwc-circular-progress.mwc-circular-progress--indeterminate circle {
  animation: 1.4s ease-in-out 0s infinite normal none mwc-circular-progress-dash;
  animation-play-state: unset;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
}

.mwc-circular-progress.mwc-circular-progress--disable-shrink circle {
  animation: none;
}`;
