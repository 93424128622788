import { html, property, unsafeCSS } from 'lit-element';
import {
  CircularProgressBase as MWCCircularProgress
} from '@gsk-platforms/mwc-circular-progress/mwc-circular-progress-base';
import { style as mwcStyle } from '@gsk-platforms/mwc-circular-progress/mwc-circular-progress-css';
import { gskStyle } from './gsk-circular-progress-css';

export class CircularProgressBase extends MWCCircularProgress {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: String })
  public label;

  @property({ type: Boolean })
  public mini;

  // @ts-ignore
  set size(value: number) {}

  // @ts-ignore
  set thickness(value: number) {}

  get size() {
    return this.mini ? 24 : 44;
  }

  get thickness() {
    return 4;
  }

  render() {
    return html`
      <div class="mwc-circular-progress__container">
        ${super.render()}
        ${this._renderLabel()}
      </div>
    `;
  }

  protected _renderLabel() {
    return this.label
    ? html`<span class="mwc-circular-progress__label">${this.label}</span>`
    : html``;
  }
}
